import React from "react"
import { css, Theme } from "@emotion/react"
import { Heading, Paragraph } from "@ncs/web-legos"
import { dollarSign, message, screwdriver, shoppingCart, truck } from "~/views/auth/login/icons"

export const LoginSignupIntro: React.FC = () => {
	return (
		<div css={introDiv}>
			<Paragraph mb={1.5} customColor="#003264" css={introTitleStyle}>
				NCS eStore
			</Paragraph>
			<Heading variant="h1" bold mb={2} css={introSubtitleStyle}>
				Making Life Better Through Carwashing
			</Heading>
			<Paragraph mb={1.5} customColor="#4B5563" css={introDescriptionStyle}>
				Our eStore connects your business to your partnership with NCS, from purchasing
				parts to managing equipment service.
			</Paragraph>
			<ul css={perksListStyle}>
				<li>
					<div css={iconContainer}>{shoppingCart()}</div>
					<span css={introBulletStyle}>Shop Parts, Chemicals, & Detail</span>
				</li>
				<li>
					<div css={iconContainer}>{truck()}</div>
					<span css={introBulletStyle}>Check Order Status</span>
				</li>
				<li>
					<div css={iconContainer}>{dollarSign()}</div>
					<span css={introBulletStyle}>Review & Pay Invoices</span>
				</li>
				<li>
					<div css={iconContainer}>{screwdriver()}</div>
					<span css={introBulletStyle}>Submit Service Requests</span>
				</li>
				<li>
					<div css={iconContainer}>{message()}</div>
					<span css={introBulletStyle}>Access & Monitor Connected Ryko Equipment</span>
				</li>
			</ul>
		</div>
	)
}

const perksListStyle = (theme: Theme) => css`
	margin: 2.5rem 0.5rem;
	${theme.breakpoints.down("sm")} {
		margin: 0;
	}
	li {
		display: flex;
		align-items: flex-start;
		column-gap: 1.1rem;
		margin-bottom: 2.25rem;
		align-items: center;
		> svg {
			position: relative;
			top: 3px;
			opacity: 0.75;
		}
		> span {
			font-size: 1.25rem;
		}
	}
`

const introDiv = css`
	background-color: #eaf5ff;
	border-radius: 1em;
	padding: 32px;
`

const iconContainer = css`
	padding: 0.5rem;
	background-color: #003264;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	align-self: center;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
`

const introBulletStyle = css`
	color: var(--Neutrals-900, #111827);
	font-family: "Atlas Grotesk";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 28px;
	letter-spacing: -0.32px;
`

const introDescriptionStyle = css`
	color: var(--Neutrals-600, #4b5563);
	font-family: "Atlas Grotesk";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: -0.32px;
`

const introSubtitleStyle = css`
	color: var(--Neutrals-900, #111827);
	font-family: "Atlas Grotesk";
	font-size: 28px;
	font-style: normal;
	font-weight: 700;
	line-height: 36px; /* 128.571% */
	letter-spacing: -0.56px;
`

const introTitleStyle = css`
	color: var(--Brand-Navy, #003264);
	font-family: "Atlas Grotesk";
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px; /* 133.333% */
	letter-spacing: 1px;
`
