import React from "react"
import { css } from "@emotion/react"
import { Icon, Link, Paragraph } from "@ncs/web-legos"

export interface NcsSocialProps {
	customColor?: string
}

export const NcsSocial: React.FC<NcsSocialProps> = ({ customColor }) => (
	<div>
		<Paragraph bold mb={1} customColor={customColor}>
			SOCIALS
		</Paragraph>
		<ul css={iconListStyle}>
			<li>
				<Link external to="https://www.facebook.com/nationalcarwashsolutions/">
					<Icon
						icon="facebook"
						family="brands"
						customColor="#00254a"
						fontSize={1}
						withContainer
					/>
				</Link>
			</li>
			<li>
				<Link external to="https://www.instagram.com/nationalcarwashsolutions/">
					<Icon
						icon="instagram"
						family="brands"
						customColor="#00254a"
						fontSize={1}
						withContainer
					/>
				</Link>
			</li>
			<li>
				<Link external to="https://www.linkedin.com/company/national-carwash-solutions">
					<Icon
						icon="linkedin"
						family="brands"
						customColor="#00254a"
						fontSize={1}
						withContainer
					/>
				</Link>
			</li>
			<li>
				<Link external to="https://www.youtube.com/channel/UC-A7544E_dtbjHBjA-qPRPA">
					<Icon
						icon="youtube"
						family="brands"
						customColor="#00254a"
						fontSize={1}
						withContainer
					/>
				</Link>
			</li>
		</ul>
	</div>
)

const iconListStyle = css`
	display: flex;
	li {
		margin-right: 0.75rem;
		&:last-of-type {
			margin-right: 0;
		}
	}
	svg {
		color: #00264b;
	}

	svg:hover {
		color: #87cefa;
	}
`
