import React, { useState, useEffect } from "react"
import { css } from "@emotion/react"
import { zodResolver } from "@hookform/resolvers/zod"
import * as Sentry from "@sentry/react"
import { useForm } from "react-hook-form"
import { useHistory } from "react-router-dom"
import { trackEvent, TrackingEvent } from "@ncs/web-legos"
import { z } from "zod"
import { isAxiosError, makeApiErrorMessage, useAuth } from "@ncs/ncs-api"
import { Box, Button, EventTracker, TextInputFormField } from "@ncs/web-legos"

export interface LoginEcommProps {
	onCreateAccount: () => void
	onClose: () => void
	onSuccess?: () => void
}

const SignInFormSchema = z.object({
	email: z.string({ required_error: "Email is required" }).email(),
	password: z.string({ required_error: "Password is required" }).min(1, "Password is required"),
})

type SignInForm = z.infer<typeof SignInFormSchema>

export const LoginEcomm: React.FC<LoginEcommProps> = ({ onCreateAccount, onClose, onSuccess }) => {
	const [isLoading, setIsLoading] = useState(false)
	const history = useHistory()
	const { login } = useAuth()

	useEffect(() => {
		const element = document.getElementById("app-main")
		if (element) {
			element.style.backgroundColor = "#F9FAFB"
		}
	}, [])

	const {
		control,
		handleSubmit,
		setError,
		formState: { isValid, errors },
	} = useForm<SignInForm>({
		resolver: zodResolver(SignInFormSchema),
	})

	const onSubmit = (data: SignInForm) => {
		setIsLoading(true)

		login(data.email, data.password)
			.then(() => {
				if (onSuccess) {
					onSuccess()
				}
				onClose()
			})
			.catch((e) => {
				setIsLoading(false)
				if (isAxiosError(e)) {
					if (e.response?.status === 401) {
						setError("password", { message: "Incorrect email/password combination" })
					} else if (e.response?.status === 403) {
						setError("password", {
							message: "Account not currently valid for use on this site",
						})
						Sentry.captureException(e)
					}
				} else {
					setError("password", {
						message: makeApiErrorMessage(e),
					})
					console.error(e)
					Sentry.captureException(e)
				}
			})
		trackEvent(TrackingEvent.SIGNIN, { email: data.email })
	}

	const forgotPasswordHandler = () => {
		history.push("/auth/forgot-password")
		onClose()
	}

	return (
		<div css={loginStyle}>
			<p css={loginWelcomeStyle}>Welcome Back!</p>
			<TextInputFormField
				control={control}
				name="email"
				label="Email Address"
				autoFocus
				placeholder="Enter email address"
				emptyValueFallback=""
				errorStyle={errors.email?.message}
				mb={"24px"}
			/>
			<TextInputFormField
				control={control}
				name="password"
				label="Password"
				onReturn={handleSubmit(onSubmit)}
				type="password"
				placeholder="Enter password"
				emptyValueFallback=""
				errorStyle={errors.email?.message}
				mb={"24px"}
			/>
			<Box
				display="flex"
				flexDirection="column"
				justifyContent="flex-end"
				alignItems="flex-end"
				rowGap={1}
			>
				<Button onClick={forgotPasswordHandler}>
					<span css={forgotPasswordStyle}>Forgot password?</span>
				</Button>
			</Box>
			<Button
				variant="primary-cta"
				fillContainer
				containerProps={{ mt: 2 }}
				onClick={handleSubmit(onSubmit)}
				isLoading={isLoading}
				disabled={!isValid}
				css={css`
					border-radius: 8px;
				`}
			>
				Sign In
			</Button>

			<div css={divider} />

			<Box display="flex" alignItems="flex-end" rowGap={1}>
				<span css={dontHaveAccountStyle}>Don’t have an account?</span>
				<EventTracker event={TrackingEvent.SIGNUP}>
					<Button onClick={onCreateAccount}>
						<span css={createOneStyle}>Create One</span>
					</Button>
				</EventTracker>
			</Box>
		</div>
	)
}

const divider = css`
	border: 1px solid #eeeeee;
	width: 100%;
	margin: 1rem 0;
`

const dontHaveAccountStyle = css`
	margin-right: 1rem;
	font-weight: 500;
	color: var(--Neutrals-900, #111827);
	font-family: "Atlas Grotesk";
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 28px; /* 155.556% */
	margin-top: -10px;
`

const createOneStyle = css`
	color: var(--Brand-Cobalt, #0b75e1);
	font-family: "Atlas Grotesk";
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: 28px; /* 155.556% */
	margin-bottom: -10px;
`

const loginStyle = css`
	padding: 32px;
	padding-top: 0px;

	label {
		color: var(--Neutrals-900, #111827);
		font-family: "Atlas Grotesk";
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 16px; /* 114.286% */
	}
`

const forgotPasswordStyle = css`
	color: var(--Brand-Cobalt, #0b75e1);
	text-align: right;
	font-family: "Atlas Grotesk";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 16px; /* 114.286% */
`

const loginWelcomeStyle = css`
	color: var(--Neutrals-900, #111827);
	text-align: center;
	font-family: "Atlas Grotesk";
	font-size: 28px;
	font-style: normal;
	font-weight: 700;
	line-height: 36px; /* 128.571% */
	letter-spacing: -0.56px;
	margin-bottom: 40px;
`
