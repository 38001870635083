import { FC, useEffect, useMemo } from "react"

import { css, Theme } from "@emotion/react"

import { OnlineChildPart, OptionVariant, VariantPartOptions } from "@ncs/ncs-api"
import { Box, Icon, Paragraph, UncheckCircleIcon } from "@ncs/web-legos"

interface PartChildSelectorProps {
	/** Comes from the Part Details endpoint called with the parent ID. */
	childParts: OnlineChildPart[]
	selectedChildId: string | null
	setSelectedChildId: (newId: string | null) => void
	label?: string
	loadingPartDetails: boolean
	sizeVariants: OptionVariant[]
	colorVariants: OptionVariant[]
	caseSizeVariants: OptionVariant[]
	selectedSizeVariant: string
	setSelectedSizeVariant: (sizeVariant: string) => void
	selectedColorVariant: string
	setSelectedColorVariant: (colorVariant: string) => void
	selectedCaseSizeVariant: string
	setSelectedCaseSizeVariant: (caseSizeVariant: string) => void
}

export const PartChildSelectorEcomm: FC<PartChildSelectorProps> = ({
	childParts,
	selectedChildId,
	setSelectedChildId,
	label = "option",
	loadingPartDetails,
	sizeVariants,
	colorVariants,
	caseSizeVariants,
	selectedSizeVariant,
	setSelectedSizeVariant,
	selectedColorVariant,
	setSelectedColorVariant,
	selectedCaseSizeVariant,
	setSelectedCaseSizeVariant,
}) => {
	const selectedChild = useMemo(() => {
		return childParts.find((part) => part.onlinePartId.toString() === selectedChildId)
	}, [selectedChildId, childParts])

	useEffect(() => {
		if (!!selectedChildId && !selectedChild && !loadingPartDetails) {
			// If you're not loading, and the selected child ID doesn't have a matching child, then
			// set out child selection to null.
			setSelectedChildId(null)
		}
	}, [selectedChildId, selectedChild, loadingPartDetails, setSelectedChildId])

	const filterSelectedVariant = (
		parts: OnlineChildPart[],
		selectedVariant: string
	): OnlineChildPart[] => {
		return parts.filter(
			(part) =>
				part?.options?.some((option) => {
					return option.variant === selectedVariant
				})
		)
	}

	useEffect(() => {
		let parts = [...childParts]
		if (selectedSizeVariant) {
			parts = filterSelectedVariant(parts, selectedSizeVariant)
		}
		if (selectedColorVariant) {
			parts = filterSelectedVariant(parts, selectedColorVariant)
		}
		if (selectedCaseSizeVariant) {
			parts = filterSelectedVariant(parts, selectedCaseSizeVariant)
		}

		setSelectedChildId(parts[0]?.onlinePartId.toString())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedCaseSizeVariant, selectedColorVariant, selectedSizeVariant, childParts])

	if (loadingPartDetails) {
		return null
	}

	if (!!selectedChildId && !selectedChild) {
		// Occasionally when part ID changes, you can get a stale child ID with a new list of
		// child parts, and it'll come back null. That's fine, it fixes itself on subsequent
		// renders.
		return null
	}

	if (childParts.length === 0) {
		return (
			<Paragraph small color="secondary">
				Sorry, there are no options currently available
			</Paragraph>
		)
	}

	const isSelectedSwitch = (selection: OptionVariant): boolean => {
		const option: string = selection.option
		const variant: string = selection.variant
		let isSelected = false

		switch (option) {
			case VariantPartOptions.Size:
				isSelected = variant === selectedSizeVariant
				break

			case VariantPartOptions.Color:
				isSelected = variant === selectedColorVariant
				break

			case VariantPartOptions.CaseSize:
				isSelected = variant === selectedCaseSizeVariant
				break

			default:
				break
		}
		return isSelected
	}

	const optionVariantSelections = (
		option: OptionVariant[],
		setVariant: (variant: string) => void
	) => {
		if (option?.length === 0) {
			return null
		}

		return (
			<div
				css={css`
					margin-bottom: 32px;
				`}
			>
				<div css={optionBoxStyle}>{option[0]?.option}</div>
				<Box display="flex" flexWrap="wrap" columnGap={1} rowGap={1} mt={1}>
					{option.map((variant) => {
						const isSelected: boolean = isSelectedSwitch(variant)
						return (
							<button
								key={variant.variant}
								css={(theme: Theme) =>
									optionButtonStyle(theme, isSelected ? "#003264" : "white")
								}
								className={isSelected ? "selected" : undefined}
								onClick={() => setVariant(variant.variant)}
							>
								<Box display="flex" alignItems="center" columnGap={0.5} mb={0.5}>
									{isSelected ?
										<Icon icon="check-circle" family="solid" color="white" />
									:	<UncheckCircleIcon
											color="#003264"
											css={css`
												margin-top: 6px;
											`}
										/>
									}
									<Paragraph
										css={(theme: Theme) =>
											styleVariantName(
												theme,
												isSelected ? "white" : "#003264"
											)
										}
									>
										{variant.variant}
									</Paragraph>
								</Box>
							</button>
						)
					})}
				</Box>
			</div>
		)
	}

	return (
		<div>
			{colorVariants && optionVariantSelections(colorVariants, setSelectedColorVariant)}
			{sizeVariants && optionVariantSelections(sizeVariants, setSelectedSizeVariant)}
			{caseSizeVariants &&
				optionVariantSelections(caseSizeVariants, setSelectedCaseSizeVariant)}
		</div>
	)
}

const styleVariantName = (theme: Theme, color: string) => css`
	color: var(--Neutrals-White, #fff);
	text-align: right;
	font-family: "Atlas Grotesk";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	color: ${color};
`

const optionBoxStyle = () => css`
	color: var(--Neutrals-900, #111827);
	font-family: "Atlas Grotesk";
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	margin-bottom: 16px;
`

const optionButtonStyle = (theme: Theme, backgroundColor: string) => css`
	min-height: 44px;
	display: flex;
	padding: 10px 20px 2px 16px;
	align-items: center;
	gap: 12px;
	border-radius: 8px;
	border: 1px solid var(--Brand-Navy, #003264);
	background-color: ${backgroundColor};
`
