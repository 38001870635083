import React, { useState } from "react"
import { css, Theme } from "@emotion/react"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { trackEvent, TrackingEvent } from "@ncs/web-legos"
import { z } from "zod"
import { CreateAccountPost, isAxiosError, makeApiErrorMessage, useAuth } from "@ncs/ncs-api"
import {
	AnimatedEntrance,
	Box,
	Button,
	Divider,
	Heading,
	Paragraph,
	TextInputFormField,
	useToast,
} from "@ncs/web-legos"

export interface CreateAccountProps {
	onLogin: () => void
	onClose: () => void
	onSuccess?: () => void
}

const NewAccountFormSchema = z.object({
	firstName: z.string({ required_error: "Please enter first name." }),
	lastName: z.string({ required_error: "Please enter last name." }),
	email: z.string({ required_error: "Please enter email address." }).email(),
	password: z
		.string({ required_error: "Please enter password." })
		.min(6, "Must be at least 6 characters long")
		.max(100, "Cannot be more than 100 characters"),
	customerNumber: z.string().max(30).nullable(),
})

type NewAccountForm = z.infer<typeof NewAccountFormSchema>

export const CreateAccountEcomm: React.FC<CreateAccountProps> = ({
	onLogin,
	onClose,
	onSuccess,
}) => {
	const { createAccountAndLogin } = useAuth()
	const { makeErrorToast } = useToast()
	const [isLoading, setIsLoading] = useState(false)

	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm<NewAccountForm>({
		resolver: zodResolver(NewAccountFormSchema),
		defaultValues: {
			customerNumber: null,
		},
	})

	const onSubmit = async (formData: NewAccountForm) => {
		const newAccountPost: CreateAccountPost = {
			firstName: formData.firstName,
			lastName: formData.lastName,
			email: formData.email,
			password: formData.password,
			customerNumber: formData.customerNumber,
			previousPurchase: !!formData.customerNumber,
			invoiceNumber: null,
		}

		try {
			setIsLoading(true)
			await createAccountAndLogin(newAccountPost)
			if (onSuccess) {
				onSuccess()
			}
			onClose()
		} catch (e) {
			if (isAxiosError(e) && typeof e.response?.data === "string") {
				makeErrorToast(e.response?.data)
			} else {
				makeErrorToast(makeApiErrorMessage(e))
			}
			setIsLoading(false)
		}
		trackEvent(TrackingEvent.SIGNUP, {
			email: formData.email,
		})
	}

	return (
		<div
			css={css`
				padding-top: 2rem;
			`}
		>
			<Box css={boxContainer}>
				<AnimatedEntrance show>
					<Paragraph css={createAccountStyle}>Create Account</Paragraph>
					<Box justifyContent="center" my={"24px"}>
						<Box display="flex" flexDirection="row">
							<TextInputFormField
								control={control}
								name="firstName"
								label="First name"
								emptyValueFallback=""
								placeholder="Enter first name"
								errorStyle={errors.firstName?.message}
								mb={"24px"}
								mr={"24px"}
							/>
							<TextInputFormField
								control={control}
								name="lastName"
								label="Last name"
								placeholder="Enter last name"
								errorStyle={errors.lastName?.message}
								emptyValueFallback=""
								mb={"24px"}
							/>
						</Box>
						<TextInputFormField
							control={control}
							name="email"
							emptyValueFallback=""
							placeholder="Enter email address"
							errorStyle={errors.email?.message}
							mb={"24px"}
						/>
						<TextInputFormField
							control={control}
							name="password"
							label="Enter password"
							type="password"
							emptyValueFallback=""
							errorStyle={errors.password?.message}
						/>
					</Box>
					<Box display="flex" flexDirection="column">
						<Heading variant="h3" bold mb={0.75} customColor="#111827">
							Link Your Account
						</Heading>
						<Paragraph customColor="#4B5563">
							To link your online account with your existing customer profile, please
							provide your NCS customer number.
						</Paragraph>
						<TextInputFormField
							mt={2}
							mb={3}
							control={control}
							name="customerNumber"
							label="Customer Number (Optional)"
							placeholder="Enter customer number"
						/>
					</Box>
					<Button
						variant="primary-cta"
						fillContainer
						css={css`
							border-radius: 8px;
						`}
						onClick={handleSubmit(onSubmit)}
						isLoading={isLoading}
					>
						Create Account
					</Button>
					<Divider />
					<Box display="flex" flexDirection="column">
						<div css={linkContainer}>
							<Paragraph
								mr={1.5}
								customColor="#111827"
								fontSize={1.125}
								css={alreadyHaveAccountStyle}
							>
								Already have an account?
							</Paragraph>
							<Button onClick={onLogin}>
								<span css={buttonLink}>Sign in</span>
							</Button>
						</div>
					</Box>
				</AnimatedEntrance>
			</Box>
		</div>
	)
}

const linkContainer = css`
	display: flex;
	margin-bottom: 1.5rem;
`

const buttonLink = css`
	color: var(--Brand-Cobalt, #0b75e1);
	font-family: "Atlas Grotesk";
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: 28px;
`
const boxContainer = (theme: Theme) => css`
	margin: 0 auto;
	background-color: #ffffff;
	padding: 3rem;
	${theme.breakpoints.only("sm")} {
		padding: 2rem;
	}
	${theme.breakpoints.only("xs")} {
		padding: 1.5rem;
	}
	padding-top: 0em;
	label {
		color: var(--Neutrals-900, #111827);
		font-family: "Atlas Grotesk";
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 16px; /* 114.286% */
	}
`

const alreadyHaveAccountStyle = css`
	color: var(--Neutrals-900, #111827);
	font-family: "Atlas Grotesk";
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 28px;
`

const createAccountStyle = css`
	color: var(--Neutrals-900, #111827);
	text-align: center;
	font-family: "Atlas Grotesk";
	font-size: 32px;
	font-style: normal;
	font-weight: 700;
	line-height: 40px;
	letter-spacing: -0.64px;
`
