import { css, SerializedStyles, Theme } from "@emotion/react"

export const globalStyles = (theme: Theme): SerializedStyles => css`
	body {
		font-family: ${theme.typography.fontFamily};
		background: ${theme.palette.background.default};
		color: ${theme.palette.text.primary};
		font-family: ${theme.typography.fontFamily};
		font-size: ${theme.typography.fontSize}px;
		font-weight: ${theme.typography.body1.fontWeight};
		overflow-y: auto;
		overflow-x: hidden;
		width: 100vw;
	}

	/* Typography */
	h1 {
		font-size: ${theme.typography.h1.fontSize};
		font-weight: ${theme.typography.h1.fontWeight};
		line-height: ${theme.typography.h1.lineHeight};
		letter-spacing: ${theme.typography.h1.letterSpacing};
	}
	h2 {
		font-size: ${theme.typography.h2.fontSize};
		font-weight: ${theme.typography.h2.fontWeight};
		line-height: ${theme.typography.h2.lineHeight};
		letter-spacing: ${theme.typography.h2.letterSpacing};
	}
	h3 {
		font-size: ${theme.typography.h3.fontSize};
		font-weight: ${theme.typography.h3.fontWeight};
		line-height: ${theme.typography.h3.lineHeight};
		letter-spacing: ${theme.typography.h3.letterSpacing};
	}
	h4 {
		font-size: ${theme.typography.h4.fontSize};
		font-weight: ${theme.typography.h4.fontWeight};
		line-height: ${theme.typography.h4.lineHeight};
		letter-spacing: ${theme.typography.h4.letterSpacing};
		text-transform: uppercase;
	}
	h5 {
		font-size: ${theme.typography.h5.fontSize};
		font-weight: ${theme.typography.h5.fontWeight};
		line-height: ${theme.typography.h5.lineHeight};
		letter-spacing: ${theme.typography.h5.letterSpacing};
	}
	h6 {
		font-size: ${theme.typography.h6.fontSize};
		font-weight: ${theme.typography.h6.fontWeight};
		letter-spacing: ${theme.typography.h6.letterSpacing};
		text-transform: ${theme.typography.h6.textTransform};
		color: ${theme.palette.text.secondary};
	}
	p {
		font-size: ${theme.typography.body1.fontSize};
	}
	a {
		text-decoration: underline;
		font-weight: bold;
		color: ${theme.palette.primary.main};
		&:hover {
			color: ${theme.palette.primary.light};
		}
	}

	/* Forms */
	input,
	select,
	textarea {
		color: inherit;
		width: inherit;
		background: white;
		border: 1px solid ${theme.palette.grey[300]};
		transition-duration: ${theme.transitions.duration.shorter}ms;
		transition-timing-function: ${theme.transitions.easing.easeOut};
		transition-property: border-color;
		&:focus,
		&:hover:not(:disabled) {
			outline: 0;
			border-color: ${theme.palette.primary.main};
		}
	}
	input,
	textarea {
		padding: 0.35rem 0.5rem 0.2rem 0.5rem;
		&::placeholder {
			opacity: 0.5;
		}
	}
	select {
		background: white;
		padding: 0.3rem 0.45rem 0.25rem 0.45rem;
		option {
			background: #eee;
		}
	}
	textarea {
		resize: vertical;
	}
	button {
		cursor: pointer;
	}

	/* Tables */
	table {
		border-collapse: collapse;
		width: 100%;
	}

	/* Animations */
	@keyframes move-to-position {
		to {
			opacity: 1;
			transform: none;
		}
	}

	/* Override FontAwesome's default spin speed to be a bit more interesting. */
	.fa-spin {
		animation: fa-spin 1s infinite cubic-bezier(0.31, -0.1, 0, 1.01);
	}
`
