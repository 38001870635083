import React, { useState } from "react"
import { css, Theme } from "@emotion/react"
import { LoginEcomm } from "./LoginEcomm"
import { LoginSignupIntro } from "./LoginSignupIntro"
import { CreateAccountEcomm } from "../create-account/CreateAccountEcomm"
import { ModalEcomm, useScreenSizeMatch } from "@ncs/web-legos"

interface LoginModalEcommProps {
	isOpen: boolean
	onClose: () => void
	onSuccess?: () => void
}

export const LoginModalEcomm: React.FC<LoginModalEcommProps> = ({
	isOpen,
	onClose,
	onSuccess,
}) => {
	const [isLoginOpen, setIsLoginOpen] = useState(true)
	const createAccountHandler = () => setIsLoginOpen(false)
	const doLoginHandler = () => setIsLoginOpen(true)
	const screenIsMediumUp = useScreenSizeMatch("md", "up")

	return (
		<>
			<ModalEcomm maxWidth={"lg"} showCloseButton={false} isOpen={isOpen} onClose={onClose}>
				<div css={outerContainerStyle}>
					{screenIsMediumUp && <LoginSignupIntro />}
					{isLoginOpen ?
						<LoginEcomm
							onCreateAccount={createAccountHandler}
							onClose={onClose}
							onSuccess={onSuccess}
						/>
					:	<CreateAccountEcomm
							onLogin={doLoginHandler}
							onClose={onClose}
							onSuccess={onSuccess}
						/>
					}
					{!screenIsMediumUp && <LoginSignupIntro />}
				</div>
			</ModalEcomm>
		</>
	)
}

const outerContainerStyle = (theme: Theme) => css`
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	padding: 1rem;
	box-sizing: content-box;
	margin: 0 auto;

	> div {
		padding-top: 2rem;
		width: 100%;
		max-width: 35rem;
	}

	${theme.breakpoints.down("sm")} {
		display: grid;
		margin: auto;
		padding-top: 0em;
	}
`
