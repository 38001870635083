export const loadNyris = () => {
	const context = getContext()
	const apiKey = import.meta.env.VITE_NYRIS_KEY
	context.nyrisSettings = {
		apiKey: apiKey,
		language: "en",
		ctaButtonText: "View more",
		browseGalleryButtonColor: "#0B75E1",
		primaryColor: "#0B75E1",
		cameraIconColour: "#0B75E1",
		initiatorElementId: ["nyris-camera"],
	}
	const s = document.createElement("script")
	s.src = "https://assets.nyris.io/libs/js/1.3/search/widget.js"
	s.onload = () => {
		const style = document.createElement("style")
		style.textContent = `
			.nyris__icon-text {
				border: 1px solid #333;
			}

			.nyris__footer {
				display: none;
			}

			.nyris__icon {
				border: 2px solid #777;
				display: none;
			}

			.nyris__button.nyris__hello-upload {
				background-color: #0B75E1;
				background-image: none;
			}

			.nyris__main-heading.nyris__main-heading--big {
				visibility: hidden;
				align-self: stretch;
				color: #000;
				font-family: "Atlas Grotesk";
				font-size: 20px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
			}

			.nyris__main-heading.nyris__main-heading--big::after {
				content: "ClickNPic";
				visibility: visible;
				align-self: stretch;
				color: #000;
				font-family: "Atlas Grotesk";
				font-size: 20px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
				float: left;
			}

			.nyris__main-description {
				align-self: stretch;
				color: var(--Neutrals-700, #374151);
				font-family: "Atlas Grotesk";
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px; /* 142.857% */
			}

			.nyris__icon-text {
				display: none;
			}

			.nyris__button {
				border-radius: 100px !important;
				background: var(--Brand-Cobalt, #0B75E1) !important;
			}

			.nyris__main-heading {
				color: #000;
				font-family: "Atlas Grotesk";
				font-size: 24px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
			}

			.nyris__wait-spinner {
				img {
					filter: hue-rotate(320deg);
				}
			}

			.nyris--purple {
				color: var(--Brand-Cobalt, #0B75E1);
				font-family: "Atlas Grotesk";
				font-size: 12px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
			}

			.nyris__main-heading {
				margin-top: .5em;
			}

			@media (min-width: 600px) {
				.nyris__main {
					border-radius: 8px;
					border: 1px solid var(--Brand-Sky, #87CEFA);
					background: var(--Neutrals-White, #FFF);
					box-shadow: 0px 0px 6px 0px #D1D5DB;
					right : 32px;
					bottom: 32px;
					min-height: 640px;
					max-width: 400px;
				}

				.nyris__close {
					top: .5em;
					right: .5em;
				}

				.nyris__close-text {
					display: none;
				}

				.nyris__close {
					width: 36px;
					background-color: #fdfdfd;
					border: 1px solid #ccc;
				}

				.nyris__close:hover {
					width: 36px;
					background-color: #ddd;
				}

				.nyris__close-light {
					display:none;
				}

				.nyris__close-dark {
					display:block;
					width: 10px;
					height: 10px;
				}

				.nyris__success-multiple-summary {
					margin-right: 20px;
				}
			}
    	`
		document.head.appendChild(style)
	}
	document.getElementsByTagName("head")[0].appendChild(s)
}

export const handleNyrisSearch = () => {
	const iconElement = document.querySelector(".nyris__icon") as HTMLElement

	if (iconElement) {
		iconElement.click()
	}

	setTimeout(() => {
		const inputElement = document.querySelector(".search-input-text input") as HTMLInputElement
		if (inputElement) {
			inputElement.blur()
		}
	})
}

const getContext: any = () => window
