import React, { memo } from "react"
import { css } from "@emotion/react"
import { Link, useHistory } from "react-router-dom"
import { PartPriceEcomm, trackEvent, TrackingEvent } from "@ncs/web-legos"
import { CustomerPart, isNonParentCustomerPart, NonParentCustomerPart } from "@ncs/ncs-api"
import { ButtonEcomm, CardProps, Paragraph, PartImage, EventTracker } from "@ncs/web-legos"

import { useShopContext } from "~/contexts"
import ComingSoon from "../../../../images/parts/coming-soon.svg"
interface PartCardProps extends CardProps {
	part: CustomerPart
	onAddToCart: (partId: string) => void
}

export const PartCardEcomm: React.FC<PartCardProps> = memo(({ part, onAddToCart, ...rest }) => {
	const history = useHistory()
	const [, shopDispatch] = useShopContext()

	const handleAddToCart = (partToAdd: NonParentCustomerPart) => {
		shopDispatch({
			type: "add part to cart",
			payload: { part: partToAdd },
		})

		// Fire the passed in handler to open to the added to cart modal.
		onAddToCart(partToAdd.id)
		trackEvent(TrackingEvent.ADD_TO_CART, {
			id: partToAdd.id,
			name: partToAdd.description,
		})
	}

	const goToProduct = (id: string) => {
		history.push(`/shop/parts/${id}`)
		trackEvent(TrackingEvent.GO_TO_PRODUCT, { id, name: part.description })
	}

	const displayNetPrice = (cardPart: CustomerPart): number | null => {
		return cardPart.isParent ? cardPart.childNetPrice : cardPart.netPrice
	}

	const partCardImg = (cardPart: CustomerPart): string => {
		let img = ComingSoon
		if (cardPart.isParent) {
			img = cardPart.childImage ?? ComingSoon
		} else {
			img = cardPart.imageUrl ?? ComingSoon
		}
		return img
	}

	return (
		<div css={partCard}>
			<>
				<EventTracker
					event={TrackingEvent.CARD}
					data={{ id: part.id, name: part.description }}
				>
					<Link to={`/shop/parts/${part.id}`}>
						<PartImage
							src={partCardImg(part)}
							css={partImageContainerStyle}
							imageCss={stylePartImage}
							alt={part.title}
							className="part-card-image"
						/>
					</Link>
				</EventTracker>

				<EventTracker
					event={TrackingEvent.CARD}
					data={{ id: part.id, name: part.description }}
				>
					<Paragraph
						mt={1.5}
						mb={0.5}
						color="secondary"
						fontSize={0.8}
						customColor={part?.brandName ? "#003264" : "transparent"}
						bold
						textTransform="uppercase"
					>
						{part?.brandName ? part.brandName : "n/a"}
					</Paragraph>
					<Link to={`/shop/parts/${part.id}`} css={headingLinkStyle}>
						<Paragraph css={productTitle} className="product-title">
							{part.title}
						</Paragraph>
					</Link>
				</EventTracker>

				<>
					<Paragraph mb={1.5} mt={0.4} color="secondary" fontSize={0.8}>
						{isNonParentCustomerPart(part) ?
							`#${part.onlinePartNumber}`
						:	`${part?.variantCount} Options Available`}
					</Paragraph>
					<PartPriceEcomm
						netPrice={displayNetPrice(part) ?? 0}
						price={part?.price}
						isParent={part?.isParent}
					/>
				</>
			</>
			{isNonParentCustomerPart(part) ?
				<ButtonEcomm
					variant="primary-cta"
					onClick={() => handleAddToCart(part)}
					fillContainer
					containerProps={{
						mt: 2,
					}}
				>
					Add To Cart
				</ButtonEcomm>
			:	<ButtonEcomm
					variant="secondary-cta"
					onClick={() => goToProduct(part.id)}
					fillContainer
					containerProps={{
						mt: 2,
					}}
				>
					See Options
				</ButtonEcomm>
			}
		</div>
	)
})

const partCard = css`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	transition: box-shadow 0.3s ease-in-out;
	padding-right: 1rem;
	max-width: 220px;
	width: 220px;
	min-width: 220px;
	max-height: 500px;
	height: 500px;
	min-height: 500px;

	a:hover,
	a:focus {
		text-decoration: none;
	}

	&:hover {
		cursor: pointer;
	}

	&:hover button.css-dvbig9-baseButtonStyle-buttonWidthStyle-variantStyle {
		background-color: #00254a;
	}

	&:hover button.css-upcgpj-baseButtonStyle-buttonWidthStyle-variantStyle {
		border: 2px solid #00254a;
		color: #00254a;
	}

	&:hover div.part-card-image {
		box-shadow: 0 0 0.375rem 0 #0b75e1;
	}

	&:hover div.product-title {
		color: #0b75e1;
	}
`

const headingLinkStyle = css`
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
`

const partImageContainerStyle = css`
	width: 100%;
	padding: 1.25rem;
	border-radius: 0.25rem;
	box-shadow: 0 0 0.375rem 0 #d1d5db;
	background-color: #fff;
	display: block;
`

const stylePartImage = css`
	max-height: 160px;
	height: 160px;
	min-height: 160px;
	max-width: 160px;
	width: 160px;
	min-width: 160px;
`

const productTitle = css`
	font-size: 1.25rem;
	font-weight: 500;
	line-height: normal;
	overflow-wrap: anywhere;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	line-height: 1.1;
	max-height: 4.5em;
	overflow: hidden;
`
